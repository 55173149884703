<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/funcionarios"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
          <div class="d-flex align-center">
            <span>Status: </span>
            <v-chip
              :color="
                funcionario.status.toLowerCase() === 'ativo' ? 'green' : 'red'
              "
              small
              class="ml-2"
              text-color="white"
              >{{ funcionario.status.toUpperCase() }}</v-chip
            >
          </div>
        </div>

        <v-expansion-panels multiple focusable class="mb-4" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header
              >Permissões do Funcionário</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <FuncionarioPermissoes
                :info="funcionario"
                :listaMesa="listMesas"
                :listaPermissoes="listRoles"
                :loading="loadingBtn"
                @editarFuncionario="editarFuncionario"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels multiple focusable>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Informações do Funcionário</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <FuncionarioInfo :info="funcionario" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Funcionário editado com sucesso"
      @close="goToFuncionariosList"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import FuncionarioInfo from "@/components/funcionarios/FuncionarioInfo";
import FuncionarioPermissoes from "@/components/funcionarios/FuncionarioPermissoes";
import {
  exibirFuncionario,
  listPermissoes,
  listMesas,
  editarAcessoDoFuncionario,
} from "@/services/user";
export default {
  name: "FuncionarioEditar",
  components: {
    FuncionarioInfo,
    FuncionarioPermissoes
  },
  data: () => ({
    funcionario: [],
    loading: false,
    panel: [0],
    breadcrumbs: [
      {
        text: "Funcionários",
        disabled: false,
        to: "/funcionarios",
      },
      {
        text: "Editar Funcionário",
        disabled: true,
        to: "",
      },
    ],
    listMesas: [],
    listRoles: [],
    success: false,
    error: false,
    messageError: null,
    loadingBtn: false,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getFuncionario();
  },
  methods: {
    async getFuncionario() {
      try {
        this.loading = true;
        this.listRole();
        this.listMesa();
        const resp = await exibirFuncionario(this.$route.params.id);
        this.funcionario = resp.data;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async listRole() {
      const resp = await listPermissoes();
      this.listRoles = resp.data;
    },
    async listMesa() {
      const resp = await listMesas();
      this.listMesas = resp.data;
    },
    async editarFuncionario(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarAcessoDoFuncionario(this.$route.params.id, event).then(
          () => {
            this.success = true;
          }
        );
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goToFuncionariosList() {
      this.sucess = false;
      this.$router.push({ name: "Funcionarios" });
    },
  },
};
</script>

<style></style>
